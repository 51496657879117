import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link } from 'gatsby'
import Image from "../image-component/image-new"
import Modal from "../modal/modal"
import ImageModal from "../modal/imageModal"
import YouTube from "react-youtube"
import HeroAnimation from "./hero-animation";
import "./lp.scss"

const socialLogos = [
    {
        "imageName": "social-proof-desktop-n.png",
        "minBreakpoint": "992px"
    },
    {
        "imageName": "social-proof-tablet-n.png",
        "minBreakpoint": "768px"
    },
    {
        "imageName": "social-proof-mobile-n.png",
        "minBreakpoint": "320px"
    }
]
const insightImages = [
    {
        "imageName": "Image-InsightSoftware-n-desktop.png",
        "minBreakpoint": "992px"
    },
    {
        "imageName": "insight-case-tablet.png",
        "minBreakpoint": "768px"
    },
    {
        "imageName": "Image-InsightSoftware-n-desktop.png",
        "minBreakpoint": "320px"
    }
]
const progressImages = [
    {
        "imageName": "Image-Progress-desktop.png",
        "minBreakpoint": "992px"
    },
    {
        "imageName": "Image-Progress-tablet.png",
        "minBreakpoint": "768px"
    },
    {
        "imageName": "Image-Progress-mobile.png",
        "minBreakpoint": "320px"
    }
]

export default function LandingPage() {
    const ref1 = useRef(null);
    const isInViewport1 = useIsInViewport(ref1);
    useEffect(() => {
        document.querySelector('footer.footer').style.display = 'none'
    }, [])
    return (
        <span className="landing-page-wrap">
            <div className="nav-logo-wrap">
                <div className="logo-section">
                    <Link to="/"><Image sourceFolder={'lp'} imgName={'SPZ-Logo-white.svg'} /></Link>
                </div>
            </div>
            <div className="hero-section">
                <div className="hero-sections-wrap">
                    <div className="hero-left">
                        <div className='hero-above-heading'>SAAS</div>
                        <div className='hero-heading'>Increase Conversions by 30%+ in 3 Months</div>
                        <div className='hero-subheading'>100+ SaaS companies (CrowdStrike, Workday, Sophos) use Spiralyze to predict winning tests and full-service implementation to design, develop and deliver conversion rate lifts.</div>
                        <Link to="/get-demo/" className="hero-cta">Get a Free Consult</Link>
                    </div>
                    <div className="hero-right">
                        <HeroAnimation />
                    </div>
                </div>
            </div>
            <div className="social-proof-section">
                <div className="social-proof-heading">Trusted by Top SaaS Companies to Drive Results</div>
                <div className="social-logos-wrap">
                    <Image sourceFolder={'lp'} imgName={socialLogos} fallBackImage={'social-proof-desktop-n.png'} />
                </div>
            </div>
            <div className="case-item">
                <div className="case-item-wrap">
                    <div className="case-left">
                        <div className="logo-wrap">
                            <Image sourceFolder={'lp'} imgName={'insight-logo.svg'} alt={'InsightSoftware logo'} />
                        </div>
                        {/* <div className="case-info">HQ in NC, USA{"\u00a0\u00a0"}<span>|</span>{"\u00a0\u00a0"}PE Funded</div> */}
                        <div className="case-copy">"I'm absolutely thrilled to partner with Spiralyze".</div>
                        <div className="case-person">
                            <Image sourceFolder={'lp'} imgName={'Warren-Lee.png'} alt={'Warren Lee'} className="person-img" />
                            <div className="person-info">
                                <div className="person-name">Warren Lee</div>
                                <div className="person-position">VP, Marketing</div>
                            </div>
                        </div>
                    </div>
                    <div className="case-right">
                        <ImageModal
                            modalButton={
                                <div className="open-image-modal"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" rx="24" fill="#FFCA1E" fillOpacity="0.8" />
                                    <path d="M23.75 20C23.75 19.5858 23.4142 19.25 23 19.25C22.5858 19.25 22.25 19.5858 22.25 20H23.75ZM22.25 26C22.25 26.4142 22.5858 26.75 23 26.75C23.4142 26.75 23.75 26.4142 23.75 26H22.25ZM20 22.25C19.5858 22.25 19.25 22.5858 19.25 23C19.25 23.4142 19.5858 23.75 20 23.75V22.25ZM26 23.75C26.4142 23.75 26.75 23.4142 26.75 23C26.75 22.5858 26.4142 22.25 26 22.25V23.75ZM32.4694 33.5301C32.7622 33.8231 33.2371 33.8233 33.5301 33.5306C33.8231 33.2378 33.8233 32.7629 33.5306 32.4699L32.4694 33.5301ZM28.6588 28.6549L29.1894 28.1248C29.0483 27.9836 28.8568 27.9045 28.6572 27.9049C28.4576 27.9053 28.2664 27.9853 28.1259 28.1271L28.6588 28.6549ZM29.7116 25.7473C29.5545 26.1306 29.7379 26.5686 30.1212 26.7257C30.5045 26.8827 30.9425 26.6994 31.0996 26.3161L29.7116 25.7473ZM22.25 20V26H23.75V20H22.25ZM20 23.75H26V22.25H20V23.75ZM23 30.25C18.9959 30.25 15.75 27.0041 15.75 23H14.25C14.25 27.8325 18.1675 31.75 23 31.75V30.25ZM15.75 23C15.75 18.9959 18.9959 15.75 23 15.75V14.25C18.1675 14.25 14.25 18.1675 14.25 23H15.75ZM23 15.75C27.0041 15.75 30.25 18.9959 30.25 23H31.75C31.75 18.1675 27.8325 14.25 23 14.25V15.75ZM33.5306 32.4699L29.1894 28.1248L28.1282 29.185L32.4694 33.5301L33.5306 32.4699ZM28.1259 28.1271C26.8137 29.452 25.0183 30.25 23 30.25V31.75C25.4278 31.75 27.6043 30.7854 29.1917 29.1827L28.1259 28.1271ZM30.25 23C30.25 23.9738 30.0584 24.901 29.7116 25.7473L31.0996 26.3161C31.5191 25.2924 31.75 24.1722 31.75 23H30.25Z" fill="#000314" />
                                </svg>
                                </div>
                            }
                            modalContent={
                                <Image sourceFolder={'lp'} imgName={'Image-InsightSoftware-zoomed-min.jpg'} />
                            }
                        />
                        <Image sourceFolder={'lp'} imgName={insightImages} fallBackImage={'insight-case-desktop.png'} alt={'InsightSoftware case'} className="case-img" />
                        <div className="case-prozac">
                            <Image sourceFolder={'lp'} imgName={'arrow-right-circle.svg'} />
                            <span>Homepage redesign</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="results-section">
                <div className="results-wrap">
                    <div className="result-item first">Results</div>
                    <div className="result-item">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">316%</div>
                        <div className="result-desc">Increase in demo requests in the first year.</div>
                    </div>
                    <div className="result-item">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">494%</div>
                        <div className="result-desc">Increase in pricing inquiries in the first year.</div>
                    </div>
                    <div className="result-item last">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">347%</div>
                        <div className="result-desc">Lift in resource downloads in the first year.</div>
                    </div>
                </div>
            </div>
            <div className="video-feedback-section">
                <div className="video-feedback-wrap">
                    <div className="video-feedback-left">
                        <Modal
                            modalButton={
                                <div
                                    className="hover-btn"
                                    style={{ cursor: "pointer" }}
                                >
                                    <Image
                                        sourceFolder={'lp'}
                                        className="video"
                                        imgName={"Image-Podium.png"}
                                        alt={"Matt Boyce"}
                                    />
                                    <div className="youtube-button">
                                        <Image
                                            sourceFolder={'lp'}
                                            imgName={'play-button.svg'}
                                            alt={"Youtube Button"}
                                            className='hover-play-btn'
                                        />
                                    </div>
                                    <div className="video-info">
                                        <Image
                                            sourceFolder={'lp'}
                                            className="black-logo"
                                            imgName={"podium-logo-video.svg"}
                                            alt={"Podium logo video"}
                                        />
                                        <div className="testimonial-play-btn d-flex">
                                            <img
                                                width="30px"
                                                height="30px"
                                                alt="play icon"
                                                className="play-icon"
                                                src="https://res.cloudinary.com/spiralyze/image/upload/v1664458113/spiralyze2021/lp/arrow-right.svg"
                                            />
                                            {"5 MIN"}
                                        </div>
                                    </div>
                                </div>
                            }
                            modalContent={
                                <div className="iframe-wrap">
                                    <YouTube videoId={'G-55Zm8r57s'} />
                                </div>
                            }
                        />
                    </div>
                    <div className="video-feedback-right">
                        <div className="logo-wrap">
                            <Image sourceFolder={'lp'} imgName={'podium-logo-black.svg'} alt={'Podium logo'} />
                        </div>
                        {/* <div className="feedback-info">HQ in UT, USA{"\u00a0\u00a0"} <span>|</span> {"\u00a0\u00a0"}Private, Series D</div> */}
                        <div className="feedback-copy">"Within two weeks, our conversion [rate] on our pricing page...went up 50%.  That meant...way more leads for our sales team, and they're some of the best quality leads we have..."</div>
                        <div className="feedback-name">Matt Boyce</div>
                        <div className="feedback-position">Sr. Director, Demand Generation</div>
                    </div>
                </div>
            </div>
            <div className="case-item">
                <div className="case-item-wrap">
                    <div className="case-left">
                        <div className="logo-wrap">
                            <Image sourceFolder={'lp'} imgName={'progress-logo.svg'} alt={'Progress logo'} />
                        </div>
                        {/* <div className="case-info">HQ in MA, USA{"\u00a0\u00a0"}<span>|</span>{"\u00a0\u00a0"}Public</div> */}
                        <div className="case-copy">"Progress Software's in-house CRO team used Spiralyze to run more tests and increase speed to results."</div>
                        <div className="case-person">
                            <Image sourceFolder={'lp'} imgName={'Megan.png'} alt={'Megan Gouveia'} className="person-img" />
                            <div className="person-info">
                                <div className="person-name">Megan Gouveia</div>
                                <div className="person-position">Sr. Manager Digital Marketing</div>
                            </div>
                        </div>
                    </div>
                    <div className="case-right">
                        <ImageModal
                            modalButton={
                                <div className="open-image-modal"><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" rx="24" fill="#FFCA1E" fillOpacity="0.8" />
                                    <path d="M23.75 20C23.75 19.5858 23.4142 19.25 23 19.25C22.5858 19.25 22.25 19.5858 22.25 20H23.75ZM22.25 26C22.25 26.4142 22.5858 26.75 23 26.75C23.4142 26.75 23.75 26.4142 23.75 26H22.25ZM20 22.25C19.5858 22.25 19.25 22.5858 19.25 23C19.25 23.4142 19.5858 23.75 20 23.75V22.25ZM26 23.75C26.4142 23.75 26.75 23.4142 26.75 23C26.75 22.5858 26.4142 22.25 26 22.25V23.75ZM32.4694 33.5301C32.7622 33.8231 33.2371 33.8233 33.5301 33.5306C33.8231 33.2378 33.8233 32.7629 33.5306 32.4699L32.4694 33.5301ZM28.6588 28.6549L29.1894 28.1248C29.0483 27.9836 28.8568 27.9045 28.6572 27.9049C28.4576 27.9053 28.2664 27.9853 28.1259 28.1271L28.6588 28.6549ZM29.7116 25.7473C29.5545 26.1306 29.7379 26.5686 30.1212 26.7257C30.5045 26.8827 30.9425 26.6994 31.0996 26.3161L29.7116 25.7473ZM22.25 20V26H23.75V20H22.25ZM20 23.75H26V22.25H20V23.75ZM23 30.25C18.9959 30.25 15.75 27.0041 15.75 23H14.25C14.25 27.8325 18.1675 31.75 23 31.75V30.25ZM15.75 23C15.75 18.9959 18.9959 15.75 23 15.75V14.25C18.1675 14.25 14.25 18.1675 14.25 23H15.75ZM23 15.75C27.0041 15.75 30.25 18.9959 30.25 23H31.75C31.75 18.1675 27.8325 14.25 23 14.25V15.75ZM33.5306 32.4699L29.1894 28.1248L28.1282 29.185L32.4694 33.5301L33.5306 32.4699ZM28.1259 28.1271C26.8137 29.452 25.0183 30.25 23 30.25V31.75C25.4278 31.75 27.6043 30.7854 29.1917 29.1827L28.1259 28.1271ZM30.25 23C30.25 23.9738 30.0584 24.901 29.7116 25.7473L31.0996 26.3161C31.5191 25.2924 31.75 24.1722 31.75 23H30.25Z" fill="#000314" />
                                </svg>
                                </div>
                            }
                            modalContent={
                                <Image sourceFolder={'lp'} imgName={'Image-Progress-zoomed-min.jpg'} />
                            }
                        />
                        <Image sourceFolder={'lp'} imgName={progressImages} fallBackImage={'Image-Progress-desktop.png'} alt={'Progress case'} className="case-img" />
                        <div className="case-prozac">
                            <Image sourceFolder={'lp'} imgName={'arrow-right-circle.svg'} />
                            <span>Demo squeeze page redesign</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="results-section bottom">
                <div className="results-wrap">
                    <div className="result-item first">Results</div>
                    <div className="result-item">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">125%</div>
                        <div className="result-desc">Increase in Sitefinity demo requests in the first year.</div>
                    </div>
                    <div className="result-item">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">83%</div>
                        <div className="result-desc">Increase in Sitefinity free trials in the first year.</div>
                    </div>
                    <div className="result-item">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">90%</div>
                        <div className="result-desc">Lift in WhatsUp Gold demo requests in the first year.</div>
                    </div>
                    <div className="result-item last">
                        <Image sourceFolder={'lp'} imgName={'chevron-up.svg'} alt={'chevron up'} />
                        <div className="result-value">78%</div>
                        <div className="result-desc">Increase in MOVEit demo requests from one test.</div>
                    </div>
                </div>
            </div>
            <div className="lp-footer" ref={ref1}>
                <Image sourceFolder={'lp'} imgName={'spz-bg-sign-8.svg'} className={isInViewport1 ? 'bg-footer animate' : 'bg-footer'} />
                <div className="lp-footer-wrap new-separator">
                    <div className="footer-left">Performance pricing</div>
                    <div className="footer-right">
                        <div className="footer-copy">No fees until you get the promised results with 100% performance pricing. We also offer flat-fee pricing if you prefer.</div>
                        <Link to="/get-demo/" className="footer-cta">Get a Free Consult</Link>
                    </div>
                </div>
            </div>
        </span>
    )
}
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIsIntersecting(entry.isIntersecting))
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}