import React, { useState } from 'react'
import Popup from 'reactjs-popup';
import './modal.scss'

export default function Modal({ modalTitle, modalContent, modalButton }) {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <>
            <span role="button" tabIndex={0} onClick={() => setOpen(o => !o)}>
                {modalButton}
            </span>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className="modal image-modal-wrap">
                    <span className="close image-modal" role="button" tabIndex={0} onClick={closeModal}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.66675 20C1.66675 9.87474 9.87486 1.66663 20.0001 1.66663C30.1253 1.66663 38.3334 9.87474 38.3334 20C38.3334 30.1252 30.1253 38.3333 20.0001 38.3333C9.87486 38.3333 1.66675 30.1252 1.66675 20ZM15.0609 13.2929C14.5727 12.8048 13.7813 12.8048 13.2931 13.2929C12.805 13.7811 12.805 14.5725 13.2931 15.0607L18.2322 19.9997L13.2931 24.9387C12.805 25.4269 12.805 26.2184 13.2931 26.7065C13.7813 27.1947 14.5727 27.1947 15.0609 26.7065L19.9999 21.7675L24.939 26.7065C25.4271 27.1947 26.2186 27.1947 26.7067 26.7065C27.1949 26.2184 27.1949 25.4269 26.7067 24.9387L21.7677 19.9997L26.7067 15.0607C27.1949 14.5725 27.1949 13.7811 26.7067 13.2929C26.2186 12.8048 25.4271 12.8048 24.939 13.2929L19.9999 18.2319L15.0609 13.2929Z" fill="#FFCA1E" />
                        </svg>
                    </span>
                    <div className="popup-content">{modalContent}</div>
                </div>
            </Popup>
        </>
    );

}